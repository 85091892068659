/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

import React from "react"
import { AuthContextProvider } from "./src/context/provider"
import SimpleReactLightbox from "simple-react-lightbox"

const App = props => {
  return <AuthContextProvider>{props.children}</AuthContextProvider>
}

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <App>{element}</App>
  </SimpleReactLightbox>
)
