exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-balicky-js": () => import("./../../../src/pages/balicky.js" /* webpackChunkName: "component---src-pages-balicky-js" */),
  "component---src-pages-formular-odeslan-js": () => import("./../../../src/pages/formular-odeslan.js" /* webpackChunkName: "component---src-pages-formular-odeslan-js" */),
  "component---src-pages-kosik-js": () => import("./../../../src/pages/kosik.js" /* webpackChunkName: "component---src-pages-kosik-js" */),
  "component---src-pages-kurz-js": () => import("./../../../src/pages/kurz.js" /* webpackChunkName: "component---src-pages-kurz-js" */),
  "component---src-pages-objednavka-odeslana-js": () => import("./../../../src/pages/objednavka-odeslana.js" /* webpackChunkName: "component---src-pages-objednavka-odeslana-js" */),
  "component---src-pages-zive-js": () => import("./../../../src/pages/zive.js" /* webpackChunkName: "component---src-pages-zive-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

