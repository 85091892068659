import { Link } from "gatsby"
import React, { useState } from "react"
import { default as Logo } from "../images/logo.svg"
import MainMenu from "./layout/MainMenu"

export default function Header({ menu, location }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="relative ">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:py-3">
          <div className="flex justify-between items-center py-2 lg:justify-start  ">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to={"/"}>
                <span className="sr-only">Wellness.cz</span>
                <img src={Logo} className="h-10 lg:h-12" alt="Logo" />
              </Link>
            </div>
            <div></div>
            <div className="lg:order-1 flex">
              <a
                href="https://www.wellness.cz/"
                className="bg-orange-800 px-6 py-1 rounded-md   text-white hover:bg-orange-900"
              >
                zpět na wellness.cz
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
